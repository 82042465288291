.blink {
    -webkit-animation: blink 2s infinite both;
    animation: blink 4s infinite both;
    border-radius: 8px;
}

@-webkit-keyframes blink {
    0%,
    50%,
    100% {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
        background-color: #cbddf1;
        border-radius: 8px;
    }
}

@keyframes blink {
    0%,
    50%,
    100% {
        /*opacity: 1;*/
        background-color: #f8f8f8;
    }
    25%,
    75% {
        /*opacity: 0;*/
        background-color: #cbddf1;
    }
}
